var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tech-links",class:{'tech-links--active': this.$route.meta.active == 5}},[_c('img',{staticClass:"normal",staticStyle:{"width":"24px"},attrs:{"src":require("@/assets/restyle/images/menu/settings_white.svg"),"alt":""}}),_c('img',{staticClass:"hover",staticStyle:{"width":"24px"},attrs:{"src":require("@/assets/restyle/images/menu/settings_green.svg"),"alt":""}}),_c('div',{staticClass:"links-block"},[_c('div',{staticClass:"links-block-link",attrs:{"id":"integration-rgsoft"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'Integration' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('Integration'))+" "),(_vm.isActiveHotSpot('Integration')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: _vm.currentTooltipForHotSpot('Integration'),
            })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1),(_vm.userCan('superadmin'))?_c('div',{staticClass:"links-block-link",attrs:{"id":"integration-autospecbase"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'Integrations' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('Integrations'))+" "),(_vm.isActiveHotSpot('Integrations')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('Integrations'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e(),(_vm.userCan('superadmin'))?_c('div',{staticClass:"links-block-link",attrs:{"id":"integration-dyn"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'Integration_element_technology' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('Integration_element_technology'))+" "),(_vm.isActiveHotSpot('Integration_element_technology')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: _vm.currentTooltipForHotSpot('Integration_element_technology'),
            })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e(),(_vm.userCan('technical_department_root'))?_c('div',{staticClass:"links-block-link"},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'list_logs' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('list_logs'))+" "),(_vm.isActiveHotSpot('list_logs')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('list_logs'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e(),(_vm.userCan('show_organisation_settings'))?_c('div',{staticClass:"links-block-link"},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'organisation_settings' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('organisation_settings'))+" "),(_vm.isActiveHotSpot('organisation_settings')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('organisation_settings'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e(),(_vm.userCan('technical_department_root'))?_c('div',{staticClass:"links-block-link"},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'hot_spots' }}},[_vm._v(" Редактирование хотспотов ")])],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }