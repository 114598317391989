<template>
  <div class="car">
    <h3 class="h3 pt-3">
      Заполненность: {{ volume }} %
    </h3>
    <component :is="car" :volume="volume" class="car-volume" />
  </div>
</template>

<script>
import car0 from "./car0.vue"
import car1 from "./car1.vue"
import car2 from "./car2.vue"

export default {
  components: {
    car0,
    car1,
    car2,
  },
  props: {
    use_car: {
      required: true,
    }
  },
  data() {
    return {
      vehicle: null,
    }
  },
  created() {
    this.vehicle = this.use_car?.vehicle;
  },
  computed: {
    car() {
      let result = 'car0';

      if (this.volume == 0) {
        result = 'car0'
      } else if (this.volume < 7) {
        result = 'car1'
      } else {
        result = 'car2'
      }
      
      return result
    },
    volume() {
      return this.use_car?.level ?? 0
    }
  }
}
</script>

<style lang="scss" scoped>
  .car {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;

    &-volume {
      width: 100%;
    }
  }
</style>