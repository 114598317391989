<template>
  <div
    class="restyle-menu"
    @mouseenter="removeFocus()"
    :class="{'position-relative': viewNewYear}"
  > 
    <div class="position-absolute top-0 start-0 z-index-0 h-100" v-if="viewNewYear">
      <img src="@/assets/new_year_2024/menu_snowflake.svg" class="h-100" style="object-fit: cover;" alt="" >
    </div>
    <icons_menu style="position: fixed; top: -100px;" />
    <div class="position-relative" >
      <div class="position-absolute top-0 start-0" v-if="viewNewYear">
        <img src="@/assets/new_year_2024/menu_garland.svg" alt="">
        <img src="@/assets/new_year_2024/menu_christmas_tree.svg" class="position-absolute" alt="" style="top: 24px; left: 5px;">
      </div>
      <router-link to="/" class="logotip-full">
          <img
          src="@/assets/restyle/images/new_logo.svg"
          alt=""
          class="logotip-full-img"
          key="logo"
        />
      </router-link>
      <router-link to="/" class="home-link">Факт</router-link>
      <!-- <div >
        <svg class="menu_svg" width="20" height="18" color="white" style="margin-left: 15px;">
          <use href="#menu_home"></use>
        </svg>
      </div> -->
      <links />
      
    </div>
    <div class="wrapper-right-menu">
      <gserver v-if="userCan('superadmin') || back_office" :back_office="back_office"/>
      <div class="restyle-menu-avatar">
        <tech v-if="userCan('superadmin') || back_office" />
        <div class="bell" @click="setIsVisibleNotPopup(true)">
          <img
            class="bell__img"
            src="@/assets/restyle/images/menu/bell.png"
            alt=""
          >
          <div v-if="getListNotifications.length" class="bell__count">
            {{ getListNotifications.length }}
          </div>
        </div>
        <user-menu />
      </div>
    </div>
  </div>
</template>

<script>
import icons_menu from "./icons.vue";
import userMenu from "@/components/UserMenu";
import links from "./links";
import tech from "./blocks/tech";
import gserver from "./blocks/gserver.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  components: {
    icons_menu,
    userMenu,
    links,
    tech,
    gserver,
  },
  data() {
    return {
      activeItem: null,
      isAtiveCursorUrlLogistick: false,
    };
  },
  methods: {
    ...mapMutations({
      setIsVisibleNotPopup: "user_notifications/SET_IS_VISIBLE_NOTIFICATIONS_POPUP",
    }),
    removeFocus() {
      document.activeElement.blur();
    },
  },
  computed: {
    ...mapGetters({
      userCan: "user/userCan",
      viewNewYear: "new_year_store/viewNewYear",
      getListNotifications: "user_notifications/GET_LIST_NOTIFICATIONS",
    }),
    back_office() {
      return this.userCan('technical_department_root') || Boolean(process.env.VUE_APP_LOCAL_DEV);
    }
  },
};
</script>

<style lang="scss" scoped>
.restyle-menu {
  display: grid;
  grid-template-columns: 1fr 101px;
  height: 100%;
  background: linear-gradient(90.04deg, #1D9C95 0%, #1D106F 100%);
}

.restyle-menu-avatar {
  display: flex;
  align-items: center;
  margin-right: 2px;
  margin-left: -22px;
}

.logotip-full {
  margin-left: 30px;
  display: inline-block;
  padding: 14px 0;

  &-img {
    height: 22px;
  }
}

.home-link {
  margin-left: 9px;
  display: inline-block;
  font-weight: 400;
  font-size: 19px;
  line-height: 26px;
  color: #FFFFFF;
  vertical-align: sub;

  & a {
    margin-left: 9px;
    display: inline-block;
    font-weight: 400;
    font-size: 19px;
    line-height: 26px;
    color: #FFFFFF;
    vertical-align: sub;
  }

  &-img {

  }
}

.bell {
  position: relative;
  cursor: pointer;
  &__img {
    height: 23px;
    width: 23px;
    margin-right: 10px;
  }
  &__count {
    position: absolute;
    top: -3px;
    right: 4px;
    background-color: red;
    color: white;
    border-radius: 15px;
    font-size: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
  }
}

.wrapper-right-menu {
  display: flex;
  align-items: center;
  width: max-content;
  margin-left: -50px;
  height: 55px;
}

@media (max-width: 576px) {
  .restyle-menu {
      display: none !important;
    }
}
</style>