const initialFilters = {
  recipient_roles: {
    options: [],
    value: null,
  },
}

export const notification_templates = {
  namespaced: true,
  state: {
    filters: initialFilters,
  },
  getters: {
    filters: state => state.filters,
  },
  actions: {
    changeFilter({ commit }, payload) {
      commit('changeFilter', payload);
    },
    clearFilter({ commit }) {
      commit('clearFilter');
    },
  },
  mutations: {
    changeFilter(state, payload) {
      state.filters = payload;
    },
    clearFilter(state) {
      state.filters = {};
    },
  }
};