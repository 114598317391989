export const inventory_query = {
    namespaced: true,
    state: {
      query: null,
    },
    getters: {
      GET_QUERY: state => {
        return state.query
      },
    },
    actions: {
      SET_QUERY({ commit }, payload) {
        commit('SET_QUERY', payload)
      },
      CLEAR_QUERY({ commit }) {
        commit('SET_QUERY', null)
      },                                           
    },
    mutations: {
      SET_QUERY(store, query) {
        store.query = query;
      },
    }
};