import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import store from '../_store/index';

const wbConnect = () => {  
  const echoConnect = () => {
    const HOST = 'ws.smartro.ru';
    const AUTH = 'Bearer ' + localStorage.getItem('auth_token');
    const options = {
      broadcaster: 'pusher',
      key: `${process.env.VUE_APP_WB_KEY}`,
      wsHost: HOST,
      cluster: 'eu',
      forceTLS: true,
      authEndpoint: `${process.env.VUE_APP_WB_WN}`,
      auth: {
        headers: {
          Authorization: AUTH,
          Accept: 'application/json',
        },
      },
      namespace: 'App.Events',
      encrypted: true,
    };

    const myEcho = new Echo({
      ...options,
      client: new Pusher(options.key, options),
    });

    store.dispatch('wbConnect/setEcho', myEcho)
  }

  const echoOpenChannel = (urlConnect, eventChannel) => {
    const myEcho = store.state.wbConnect.echo;
    if (myEcho) {
      myEcho
        .private(urlConnect)
        .listen(eventChannel, (event) => {
          let wbEvent = new CustomEvent(`${urlConnect}`, {bubbles: true, detail: event})
          document.dispatchEvent(wbEvent)
        });
    } else {
      alert(`Нет myEcho, подключиться к каналу ${urlConnect} невозможно`)
    }
  }

  const echoCloseChannel = (urlConnect) => {
    const myEcho = store.state.wbConnect.echo;
    myEcho.leave(urlConnect);
  }

  return {
    echoConnect,
    echoOpenChannel,
    echoCloseChannel,
  }
}

export { wbConnect }