var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"all-links"},[(
    _vm.userCan('admin') || _vm.userCan('superadmin')
    || _vm.userCan('organisation_read_all') || _vm.userCan('role_read_all')
    || _vm.userCan('user_read_all') || _vm.userCan('container_type_read_all')
    || _vm.userCan('container_platform_type_read_all') || _vm.userCan('failure_reason_read_all')
    || _vm.userCan('breakdowns_type_read_all') || _vm.userCan('payment_category_name_read_all')
    || _vm.userCan('work_order_cancelation_reason_read_all') || _vm.back_office)?_c('div',{staticClass:"all-link",class:{'all-link-active': this.$route.meta.active == 1},attrs:{"id":"links-reference"},on:{"mouseover":function($event){return _vm.setIsHoverActive(true)},"mouseleave":function($event){return _vm.setIsHoverActive(false)}}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('directories-menu'))+" "),(_vm.isActiveHotSpot('directories-menu')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('directories-menu'),
            tooltipPosition: 'right'
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()]),_c('img',{staticClass:"normal",attrs:{"src":require("@/assets/restyle/images/menu/chevron.svg"),"alt":""}}),_c('img',{staticClass:"hover",attrs:{"src":require("@/assets/restyle/images/menu/chevron-green.svg"),"alt":""}}),_c('reference',{staticClass:"active-block",attrs:{"back_office":_vm.back_office}})],1):_vm._e(),(_vm.userCan('container_platform_card_read_all') || _vm.userCan('container_card_read_all'))?_c('div',{staticClass:"all-link",class:{'all-link-active': this.$route.meta.active == 2},attrs:{"id":"links-inventory"},on:{"mouseover":function($event){return _vm.setIsHoverActive(true)},"mouseleave":function($event){return _vm.setIsHoverActive(false)}}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('inventory-menu'))+" "),(_vm.isActiveHotSpot('inventory-menu')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('inventory-menu'),
            tooltipPosition: 'right'
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()]),_c('img',{staticClass:"normal",attrs:{"src":require("@/assets/restyle/images/menu/chevron.svg"),"alt":""}}),_c('img',{staticClass:"hover",attrs:{"src":require("@/assets/restyle/images/menu/chevron-green.svg"),"alt":""}}),_c('inventory',{staticClass:"active-block"})],1):_vm._e(),(_vm.userCan('monitoring_read') || _vm.userCan('vehicle_read_all') || _vm.back_office)?_c('div',{staticClass:"all-link",class:{'all-link-active': this.$route.meta.active == 3},attrs:{"id":"links-fact"},on:{"mouseover":function($event){return _vm.setIsHoverActive(true)},"mouseleave":function($event){return _vm.setIsHoverActive(false)}}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('fact-menu'))+" "),(_vm.isActiveHotSpot('fact-menu')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('fact-menu'),
            tooltipPosition: 'right'
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()]),_c('img',{staticClass:"normal",attrs:{"src":require("@/assets/restyle/images/menu/chevron.svg"),"alt":""}}),_c('img',{staticClass:"hover",attrs:{"src":require("@/assets/restyle/images/menu/chevron-green.svg"),"alt":""}}),_c('fact',{staticClass:"active-block",attrs:{"back_office":_vm.back_office}})],1):_vm._e(),(_vm.userCan('show_logistic_menu'))?_c('div',{staticClass:"all-link",class:{'all-link-active': this.$route.meta.active == 4},attrs:{"id":"links-logistic"}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('logistic-menu'))+" "),(_vm.isActiveHotSpot('logistic-menu')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('logistic-menu'),
            tooltipPosition: 'right'
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()]),_c('img',{staticClass:"normal",attrs:{"src":require("@/assets/restyle/images/menu/chevron.svg"),"alt":""}}),_c('img',{staticClass:"hover",attrs:{"src":require("@/assets/restyle/images/menu/chevron-green.svg"),"alt":""}}),_c('logistic',{staticClass:"active-block"})],1):_vm._e(),(_vm.userCan('technical_department_root'))?_c('div',{staticClass:"all-link",class:{ 'all-link-active': this.$route.meta.active == 7 },attrs:{"id":"links-installer"}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('installer-menu'))+" "),(_vm.isActiveHotSpot('installer-menu')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
          hoverEvent: $event,
          tooltipText: _vm.currentTooltipForHotSpot('installer-menu'),
          tooltipPosition: 'right'
        })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()]),_c('img',{staticClass:"normal",attrs:{"src":require("@/assets/restyle/images/menu/chevron.svg"),"alt":""}}),_c('img',{staticClass:"hover",attrs:{"src":require("@/assets/restyle/images/menu/chevron-green.svg"),"alt":""}}),_c('installer',{staticClass:"active-block"})],1):_vm._e(),(_vm.userCan('show_reports_menu'))?_c('div',{staticClass:"all-link",class:{'all-link-active': this.$route.meta.active == 6},attrs:{"id":"report-work-drivers"}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('reports-menu'))+" "),(_vm.isActiveHotSpot('reports-menu')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('reports-menu'),
            tooltipPosition: 'right'
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()]),_c('img',{staticClass:"normal",attrs:{"src":require("@/assets/restyle/images/menu/chevron.svg"),"alt":""}}),_c('img',{staticClass:"hover",attrs:{"src":require("@/assets/restyle/images/menu/chevron-green.svg"),"alt":""}}),_c('reports',{staticClass:"active-block"})],1):_vm._e(),(_vm.userCan('notification_show'))?_c('div',{staticClass:"all-link",class:{'all-link-active': this.$route.meta.active == 8},attrs:{"id":"notifications"}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('notifications'))+" "),(_vm.isActiveHotSpot('notifications')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('notifications'),
            tooltipPosition: 'right'
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()]),_c('img',{staticClass:"normal",attrs:{"src":require("@/assets/restyle/images/menu/chevron.svg"),"alt":""}}),_c('img',{staticClass:"hover",attrs:{"src":require("@/assets/restyle/images/menu/chevron-green.svg"),"alt":""}}),_c('notifications',{staticClass:"active-block"})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }