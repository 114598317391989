var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gserver-links",class:{'gserver-links--active': this.$route.meta.active == 8}},[_c('img',{staticClass:"normal",staticStyle:{"width":"24px","margin-right":"17px"},attrs:{"src":require("@/assets/restyle/images/menu/gserver.svg"),"alt":""}}),_c('img',{staticClass:"hover",staticStyle:{"width":"24px","margin-right":"17px"},attrs:{"src":require("@/assets/restyle/images/menu/gserver_green.svg"),"alt":""}}),_c('div',{staticClass:"links-block"},[(_vm.back_office)?_c('div',{staticClass:"links-block-link"},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'vehicle_sensor' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('vehicle_sensor'))+" "),(_vm.isActiveHotSpot('vehicle_sensor')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('vehicle_sensor'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e(),(_vm.back_office)?_c('div',{staticClass:"links-block-link"},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'all_list_sensor_components' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('all_list_sensor_components'))+" "),(_vm.isActiveHotSpot('all_list_sensor_components')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('all_list_sensor_components'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e(),_c('div',{staticClass:"links-block-link"},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'command_gserver' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('command_gserver'))+" "),(_vm.isActiveHotSpot('command_gserver')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('command_gserver'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }