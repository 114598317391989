export const inspector_task = {
  namespaced: true,
  state: {
    inspectorTaskFilters: {
      id: '',
      inspector_name: '',
    },
    date: null,
    isUpdateInspectorTasks: false,
    activeTask: null,
    isLoading: false,
  },
  getters: {
    inspectorTaskFilters: state => state.inspectorTaskFilters,
    date: state => state.date,
    isUpdateInspectorTasks: state => state.isUpdateInspectorTasks,
    activeTask: state => state.activeTask,
    isLoading: state => state.isLoading,
  },
  actions: {
    changeInspectorTaskFilters({ commit }, payload) {
      commit('changeInspectorTaskFilters', payload);
    },
    changeDate({ commit }, payload) {
      commit('changeDate', payload);
    },
    resetState({ commit }) {
      commit('resetState')
    },
    updateInspectorTasks({ commit }) {
      commit('updateInspectorTasks');
    },
    finishUpdateInspectorTasks({ commit }) {
      commit('finishUpdateInspectorTasks');
    },
    changeActiveTask({ commit }, payload) {
      commit('changeActiveTask', payload);
    },
    setLoading({ commit }, payload) {
      commit('setLoading', payload);
    },
  },
  mutations: {
    changeInspectorTaskFilters(state, payload) {
      state.inspectorTaskFilters = payload;
    },
    changeDate(state, payload) {
      state.date = payload;
    },
    updateInspectorTasks(state) {
      state.isUpdateInspectorTasks = true;
    },
    finishUpdateInspectorTasks(state) {
      state.isUpdateInspectorTasks = false;
    },
    resetState(state) {
      state.inspectorTaskFilters = {
        id: '',
        inspector_name: '',
      };
      state.date = null;
      state.isUpdateInspectorTasks = false;
      state.activeTask = null;
      state.isLoading = false;
    },
    changeActiveTask(state, payload) {
      state.activeTask = payload;
    },
    setLoading(state, payload) {
      state.isLoading = payload;
    },
  },
};