<template>
  <div class="reference-block scrollbar scrollbar--secondary">
    <div
      id="inventory-map"
      class="reference-block-link"
      v-if="userCan('container_platform_card_read_all')"
    >
      <router-link :to="{ name: 'inventory' }" class="links-item">
        <div class="link-flex">
            {{ getByHotSpotRouteTitleMenu('inventory') }}
          <div v-if="isActiveHotSpot('inventory')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('inventory'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
      </router-link>
    </div>
    <div
      id="inventory-platforms"
      class="reference-block-link"
      v-if="userCan('container_platform_card_read_all')"
    >
      <router-link :to="{ name: 'inventory_platform' }" class="links-item">
        <div class="link-flex">
            {{ getByHotSpotRouteTitleMenu('inventory_platform') }}
          <div v-if="isActiveHotSpot('inventory_platform')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('inventory_platform'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
      </router-link>
    </div>
    <div
      id="inventory-inspectors"
      class="reference-block-link"
      v-if="userCan('inspectors_show') || userCan('incpectors_manage')"
    >
      <router-link :to="{ name: 'inventory_inspectors' }" class="links-item">
        <div class="link-flex">
            {{ getByHotSpotRouteTitleMenu('inventory_inspectors') }}
          <div v-if="isActiveHotSpot('inventory_inspectors')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('inventory_inspectors'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
      </router-link>
    </div>
    <div
      id="inventory-inspector-tasks"
      class="reference-block-link"
      v-if="userCan('inspectors_show')"
    >
      <router-link :to="{ name: 'inspector_task' }" class="links-item">
        <div class="link-flex">
            {{ getByHotSpotRouteTitleMenu('inspector_task') }}
          <div v-if="isActiveHotSpot('inspector_task')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('inspector_task'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      userCan: "user/userCan",
      getByHotSpotRouteTitleMenu: "hotspots/getByHotSpotRouteTitleMenu",
      allListActiveHotSpots: "hotspots/allListActiveHotSpots",
    }),
  },
  methods: {
    isActiveHotSpot(menu) {
      const rusNameMenu = this.getByHotSpotRouteTitleMenu(menu)
      const result = this.allListActiveHotSpots.find(item => item.menu === rusNameMenu);
      return result;
    },
    currentTooltipForHotSpot(menu) {
      const hotSpot = this.isActiveHotSpot(menu);
      if (Object.entries(hotSpot).length &&  hotSpot.tooltip) {
        return hotSpot.tooltip
      } 
      return ''
    }
  }
};
</script>

<style lang="scss" scoped>
.reference-block {
  padding: 20px 25px;
  z-index: 1;
  display: grid;
  row-gap: 20px;
  position: absolute;
  top: 50px;
  left: 0;
  width: 327px;
  border-radius: 5px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 566px;
  &::after {
    margin-top: 2px;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 398px;
    height: calc(100% - 2px);
    background: #1d7389;
    box-shadow: 4px 4px 8px 2px rgba(57, 134, 144, 0.5);
    border-radius: 5px;
  }

  &-link {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    height: 100%;

    & a {
      display: inline-block;
      width: 100%;
      height: 100%;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
    }

    &:hover {
      & a {
        color: #44ff8f;
      }
    }
  }
  .link-flex {
    display: flex;
    align-items: center
  }
}
</style>