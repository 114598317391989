const initialFilters = {
  srp_id: null,
  counties: {
    options: [],
    value: null,
  },
  inspectors: {
    options: [],
    value: null,
  },
  localities: {
    options: [],
    value: null,
  },
  lots: {
    options: [],
    value: null,
  },
  statuses: {
    options: [],
    value: null,
  },

}

export const inventory_inspectors = {
  namespaced: true,
  state: {
    filters: initialFilters,
  },
  getters: {
    filters: state => state.filters,
  },
  actions: {
    changeFilter({ commit }, payload) {
      commit('changeFilter', payload);
    },
    clearFilter({ commit }) {
      commit('clearFilter');
    },
  },
  mutations: {
    changeFilter(state, payload) {
      state.filters = payload;
    },
    clearFilter(state) {
      state.filters = {};
    },
  }
};