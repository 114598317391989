const initialFilters = {
  platform_address: '',
  comment: '',
  schedules: {
    options: [],
    value: null,
  },
  platform_srp_id: null,
  srp_id: null,
  emitters: {
    options: [],
    value: null,
  },
  container_type: null,
  platform_types: {
    options: [],
    value: null,
  },
  inspectors: {
    options: [],
    value: null,
  },
}

export const Inventory_monitoring_value = {
  namespaced: true,
  state: {
    filters: initialFilters,
  },
  getters: {
    filters: state => state.filters,
  },
  actions: {
    changeFilter({ commit }, payload) {
      commit('changeFilter', payload);
    },
    clearFilter({ commit }) {
      commit('clearFilter');
    },
  },
  mutations: {
    changeFilter(state, payload) {
      state.filters = payload;
    },
    clearFilter(state) {
      state.filters = initialFilters;
    },
  }
};